import React, {useEffect, useRef, useState} from "react";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";
import {
  FiHome,
  FiUser,
  FiSettings,
  FiGrid,
  FiCast,
  FiPhoneOutgoing,
} from "react-icons/fi";
import bouthaina from "../../assets/img/logo/bouthaina.png"

const Header = () => {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);
  const mostRecentScrollToHash = useRef("");

  const scrollToHash = () => {
    if (window.location.hash) {
      let hash = window.location.hash.replace('#', '');
      const element = document.getElementById(hash);
      if (element && hash !== mostRecentScrollToHash.current) {
        element.scrollIntoView({
          behavior: 'smooth'
        });
        mostRecentScrollToHash.current = hash;
      }
    }
  }

  useEffect(() => {
    scrollToHash()
  }, );

  return (
    <>
      {/* TOPBAR  */}
      <div className={navbar ? "beny_tm_topbar animate" : "beny_tm_topbar"}>
        <div className="in">
          <div className="topbar_inner">
            <div className="logo">
              <Link to="/">
                <img src={bouthaina} alt="brand" />
              </Link>
            </div>
            <div className="menu">
              <Scrollspy
                  className="anchor_nav"
                  items={[
                    "home",
                    "about",
                    "service",
                    "portfolio",
                    "news",
                    "contact",
                  ]}
                  currentClassName="current"
                  offset={-88}
              >
                <li className="current">
                  <a onClick={() => document.getElementById('home')?.scrollIntoView()}>
                    <span className="first">Home</span>
                    <span className="second">Home</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => document.getElementById('about')?.scrollIntoView()}>
                    <span className="first">About</span>
                    <span className="second">About</span>
                  </a>
                </li>
                <li>
                  <Link to={"/projects"}>
                    <span className="first">UX Projects</span>
                    <span className="second">UX Projects</span>
                  </Link>
                </li>
                <li>
                  <a onClick={() => document.getElementById('service')?.scrollIntoView()}>
                    <span className="first">Service</span>
                    <span className="second">Service</span>
                  </a>
                </li>
                {/*<li>*/}
                {/*  <a href="#portfolio">*/}
                {/*    <span className="first">Portfolio</span>*/}
                {/*    <span className="second">Portfolio</span>*/}
                {/*  </a>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <a href="#news">*/}
                {/*    <span className="first">News</span>*/}
                {/*    <span className="second">News</span>*/}
                {/*  </a>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <a href="#contact">*/}
                {/*    <span className="first">Contact</span>*/}
                {/*    <span className="second">Contact</span>*/}
                {/*  </a>*/}
                {/*</li>*/}
                <li>
                  <a href="https://drive.google.com/file/d/1nDZCrDzFouTaTdfGggDBWTeo0Zl5Tl7a/view?usp=drive_link">
                    <span className="wrapper">
                      <span className="first">Download my CV</span>
                      <span className="second">Download my CV</span>
                    </span>
                  </a>
                </li>
              </Scrollspy>
            </div>
          </div>
        </div>
      </div>
      {/* /TOPBAR */}

      <div className="mobile-menu-wrapper">
        <Scrollspy
            className="mobile_menu-icon"
            items={["home", "about", "service", "portfolio", "news", "contact"]}
          currentClassName="current"
          offset={-65}
        >
          <li>
            <a href="#home">
              <FiHome />
              <span>Homepage</span>
            </a>
          </li>
          <li>
            <a href="#about">
              <FiUser />
              <span>About me</span>
            </a>
          </li>
          <li>
            <a href="#service">
              <FiSettings />
              <span>My serivces</span>
            </a>
          </li>
          {/*<li>*/}
          {/*  <a href="#portfolio">*/}
          {/*    <FiGrid />*/}
          {/*    <span>Portfolio</span>*/}
          {/*  </a>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <a href="#news">*/}
          {/*    <FiCast />*/}
          {/*    <span>News</span>*/}
          {/*  </a>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <a href="#contact">*/}
          {/*    <FiPhoneOutgoing />*/}
          {/*    <span>Contact</span>*/}
          {/*  </a>*/}
          {/*</li>*/}
        </Scrollspy>
      </div>
      {/* End mobile-menu */}
    </>
  );
};

export default Header;
